<template>
  <div class="title-bar">
    <span>{{route.meta.title}}</span>
  </div>
  <a-locale-provider :locale="zhCN">
    <router-view :key="$route.fullPath"/>
  </a-locale-provider>
</template>
<script>
import { useRouter, useRoute } from 'vue-router';
import * as userApi from '@/api/user'
import session from '@/utils/session'
import { reactive, toRaw, computed, defineComponent, ref, watch, watchEffect, onMounted } from 'vue';
import Login from './components/admin/login'
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN';
export default {
  name: 'App',
  components: {
  },
  beforeRouteUpdate(to, from, next){

  },
  setup(prop, context){
    const router = useRouter()
    const route = useRoute()
    watch(async _ => {
      // alert(route.name)
    })
    /* 加载后 */
    onMounted(async () => {
      await router.isReady();
      if(route.path == '/'){
        // router.push({ name: 'admin-orders' })
      }
    });
    return {
      zhCN,
      route
    }
  }
}
</script>
<style>
 html, body {
   height:100%;
 }
 #app {
   font-family: Avenir, Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   color: #2c3e50;
   height:100%;
 }
 .title-bar{
   background:#08f;
   padding:10px 5px;
   position: fixed;
   width:100%;
   top:0;
   height:40px;
   padding-left:10px;
   z-index:999;
 }
 .title-bar a{
   color:#fff;
   text-decoration:underline;
   margin-right:20px;
   cursor:pointer;
 }
 .title-bar span.active{
   color:#000;
   text-decoration:none;
 } 
</style>
<style scoped>
 .loginFrame{
   display:flex;
   height:100%;
   justify-content:center;
   align-items:center;
 }
 .loginForm{
   width:500px;
   height:250px;
   max-width:90vw;
   border:1px solid #999;
   border-radius:5px;
   padding:5px;
   padding-top:40px;
 }
 .list-item{
   display:flex;
   padding:10px;
   border-bottom:1px dashed #555;
 }
 .list-item .center-cell{
   margin-left:10px;
   display:flex;
   flex-direction:column;
   text-align:left;
   flex:1;
 }
 .list-item .learn-button{
   border-radius:5px;
   background:#08f;
   padding:5px 10px;
   font-size:12px;
   color:#fff;
   line-height:18px;
 }
 .list-item .right-cell{
   text-align:right;
   display:flex;
   align-items:center;
   justify-content:center;
 }
</style>
