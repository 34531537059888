import axios from 'axios'
import session from '@/utils/session'
import notification from 'ant-design-vue/es/notification'

const requester = axios.create({
    baseURL: process.env.VUE_APP_API_URL, // VUE_APP_API_BASE_URL,
    timeout: 60000
})

// axios.defaults.baseURL = process.env.VUE_APP_API_URL

const request = function (p) {
    return new Promise((resolve) => {
        requester(p).then(res => {
            // console.log(res)
            if (res.headers.authorization) {
                // alert(res.headers.authorization)
                session.set(res.headers.authorization)
            }
            let data = res.data
            if (data && data.data && data.code !== 200) {
                if (data.data.reason === 'TOKEN_EXPIRED') {
                    // Event.$emit('NOTIFY', 'TOKEN_EXPIRED', '')
                    session.remove()
                    window.location.href = '/admin/login' 
                } else {
                    resolve(res.data)
                }
            } else {
                resolve(res.data)
            }
        }).catch(e => {
            alert('网络错误: ' + (e.message || e))
        })
    })
}

const errorHandler = (error) => {
    if (error.response) {
        const data = error.response.data
        if (error.response.status === 403) {
            notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
    }
  }
  return Promise.reject(error)
}

// request interceptor
requester.interceptors.request.use(config => {
  config.headers['returnDataLang'] = 'en'
  config.headers['Access-Control-Allow-Origin'] = '*'
  const token = session.get()
  if (token) {
    config.headers['Authorization'] = token
  }
  return config
}, errorHandler)

export default request
