import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
  path: '/order/new/success/:client',
  name: 'order-new-success',
  component: () => import('@/components/order/new_success'),
  meta: {  title: '新建订单'}
},{  
  path: '/order/new/:client',
  name: 'order-new',
  component: () => import('@/components/order/new'),
  meta: {title: '新建订单',}
},{  
  path: '/order/my/:client',
  name: 'order-my',
  component: () => import('@/components/order/my'),
  meta: {title: '我的订单',}
},{
  path: '/admin/user/list',
  name: 'admin-users',
  component: () => import('@/components/admin/users'),
  meta: {title: '用户管理',}  
},{
  path: '/admin/test',
  name: 'admin-test',
  title: '',
  component: () => import('@/components/admin/test'),
  meta: {}
},{
  path: '/admin/exchange/list',
  name: 'admin-exchanges',
  title: '卡密管理',
  component: () => import('@/components/admin/exchanges'),
  meta: {}
    
},{
  path: '/admin/order/list',
  name: 'admin-orders',
  title: '订单管理',
  component: () => import('@/components/admin/orders'),
  meta: {}
  
},{
  path: '/admin/login',
  name: 'admin-login',
  title: '登录',
  component: () => import('@/components/admin/login'),
  meta: {}
},{
  path: '/admin',
  name: 'admin',
  title: '',
  meta: {}  
}]

const router = createRouter({
  history: createWebHistory(),  // hash 模式，想要改为history 模式可以使用createWebHistory
  routes
})

export default router
