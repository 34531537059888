import { createApp } from 'vue'
import App from './App.vue'
import AppAdmin from './AppAdmin'

import router from './route'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import zhCn from "element-plus/es/locale/lang/zh-cn";

import { LocaleProvider } from 'ant-design-vue';  
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN';  

const isAdmin = !!(location.pathname.match(/^\/admin(\/|$)/i))
const rootView = isAdmin ? AppAdmin : App;

createApp(rootView)
  .use(Antd)
  .use(LocaleProvider, {locale: zhCN})
  .use(ElementPlus, {locale:zhCn})
  .use(router)
  .mount('#app')
