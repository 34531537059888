<template>
  <div class="pageLayout">
    <div class="loginFrame">
      <a-form title="" class="loginForm" :label-col="{span:5}" :wrapper-col="{span:9}">
        <a-form-item label="用户名">
          <a-input v-model:value="loginModel.username" />
        </a-form-item>
        <a-form-item label="密码">
          <a-input-password v-model:value="loginModel.password" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 1, offset: 5 }">
          <div class="warn" v-if="failed">Login failed</div>
          <a-button type="primary" @click.prevent="handleLogin">登录</a-button>
        </a-form-item>
      </a-form>
    </div>

  </div>
</template>
<script>
  import { reactive, ref } from 'vue';
  import * as userApi from '@/api/user';
  import { useRouter, useRoute } from 'vue-router';
  import session from '@/utils/session'
  export default {
    name: 'App',
    components: {
    },
    emits:['success'],    
    setup(prop, context){
      const loginModel = reactive({})
      const route = useRoute()
      const router = useRouter()
      const failed = ref(false)
      const isLogin = () => {
        return this.forceLogin || route.name === 'login'
      }
      // router.push({ name: 'admin-orders' })
      const handleLogin = () => {
        failed.value = false
        userApi.login(loginModel).then((r) => {
          if(r.code == 200) {
            context.emit('success')
          }else{
            failed.value = true
          }
        })
      }
      return {
        loginModel,
        isLogin,
        handleLogin
      }
    }
  }
</script>
<style scoped>
 .pageLayout {
   height:100%;
   display:flex;
   justify-content:center;
   align-items:center;
 }
 .loginFrame {
   margin:auto;
 }
 .loginForm {
   width:500px;
   height:250px;
   max-width:90vw;
   border:1px solid #fff;
   border-radius:5px;
   padding:5px;
   padding-top:40px;
 }
 .list-item{
   display:flex;
   padding:10px;
   border-bottom:1px dashed #555;
 }
 .list-item .center-cell{
   margin-left:10px;
   display:flex;
   flex-direction:column;
   text-align:left;
   flex:1;
 }
 .list-item .learn-button{
   border-radius:5px;
   background:#08f;
   padding:5px 10px;
   font-size:12px;
   color:#fff;
   line-height:18px;
 }
 .list-item .right-cell{
   text-align:right;
   display:flex;
   align-items:center;
   justify-content:center;
 }
</style>
