import storage from 'store'

const session = {
  set: token => {
    // sessionStorage.setItem('Access-Token', token)
    storage.set('Access-Token', token, 1000)
  },
  get: () => {
    // return sessionStorage.setItem('Access-Token')
    return storage.get('Access-Token')
  },
  remove: () => {
    // sessionStorage.removeItem("key");
    storage.remove('Access-Token')
  }
}

// sessionStorage.setItem("key", "value");
// 读取数据语法：
// var lastname = sessionStorage.getItem("key");
// 删除指定键的数据语法：
// sessionStorage.removeItem("key");

export default session
