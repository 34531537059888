<template>
  <div class="title-bar">
    <span>订单管理平台 {{route.title}}</span>
    <span v-if="logged == 1" style="margin-left:30px">
      <router-link :to="{name:'admin-orders'}">订单管理</router-link>
      <router-link :to="{name:'admin-users'}" v-if="userInfo.role == 'admin'">人员管理</router-link>
      <a @click="logout" style="float:right;cursor:pointer;">
        退出登录
        ({{userInfo.username}})
      </a>
    </span>
  </div>
  <Login v-if="logged == 0"  @success="handleLoginSuccess"></Login>
  <div v-else-if="logged == 1">
    <a-locale-provider :locale="zhCN">
      <router-view :key="$route.fullPath"/>
    </a-locale-provider>
  </div>
</template>
<script>
import { useRouter, useRoute } from 'vue-router';
import * as userApi from '@/api/user'
import session from '@/utils/session'
import { reactive, toRaw, computed, defineComponent, ref, watch, watchEffect, onMounted } from 'vue';
import Login from './components/admin/login'
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN';
export default {
  name: 'App',
  components: {
    Login
  },
  beforeRouteUpdate(to, from, next){
    // alert(to)
  },
  setup(prop, context){
    const router = useRouter()
    const route = useRoute()
    const userInfo = ref({})
    const logged = ref(-1)
    const inited = ref(false)

    watch(async _ => {
      // alert(route.name)
    })
    
    function loadUserInfo(){
      userApi.getUserInfo().then(res => {
        if (res.code === 200) {
          // console.log(res.data)
          userInfo.value = res.data
          // userInfo.value.role = 'union'
          if (res.data.status === 'active') {
            // this.forceLogin = false
            logged.value = 1
          } else if (res.data.status === 'inactive') {
            // alert('用户未激活')
          } else if (res.data.status === 'locked') {
            // alert('用户被锁定')
          }
        }else{
          // alert('登录失败')
        }
      })
    }
    /* 加载后 */
    onMounted(async () => {
      await router.isReady();
      const token = session.get()
      if (!token) {
        logged.value = 0
        if (route.name == 'admin') {
          router.push({ name: 'admin-login' })
        }
      } else {
        loadUserInfo()
        if(route.name == 'admin' || route.name == 'admin-login'){
          router.push({ name: 'admin-orders' }) /** 默认路由 */
        }
      }
    });
    const isLogin = () => {
      return route.name === 'admin-login'
      // return this.forceLogin || route.name === 'login'
    }
    const handleLoginSuccess = async () => {
      loadUserInfo()
      if(route.name == 'admin-login'){
        router.push({ name: 'admin-orders' })
      }
    }
    const logout = () => {
      session.remove()
      logged.value = 0
      // router.push({ name: 'admin-login' })
      /* if (this.$route.name !== 'admin-login') {
       *   this.$router.push({ name: 'admin-login' })
       * } */
    } 
    return {
      logged,
      isLogin,
      handleLoginSuccess,
      userInfo,
      logout,
      zhCN,
      route
    }
  }
}
</script>
<style>
 html, body {
   height:100%;
 }
 #app {
   font-family: Avenir, Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   color: #2c3e50;
   height:100%;
 }
 .title-bar{
   background:#08f;
   padding:10px 5px;
   position: fixed;
   width:100%;
   top:0;
   height:40px;
   padding-left:10px;
 }
 .title-bar a{
   color:#9df;
   text-decoration:underline;
   margin-right:20px;
   cursor:pointer;
 }
 .title-bar span.active{
   color:#000;
   text-decoration:none;
 } 
</style>
<style scoped>
 .loginFrame{
   display:flex;
   height:100%;
   justify-content:center;
   align-items:center;
 }
 .loginForm{
   width:500px;
   height:250px;
   max-width:90vw;
   border:1px solid #999;
   border-radius:5px;
   padding:5px;
   padding-top:40px;
 }
 .list-item{
   display:flex;
   padding:10px;
   border-bottom:1px dashed #555;
 }
 .list-item .center-cell{
   margin-left:10px;
   display:flex;
   flex-direction:column;
   text-align:left;
   flex:1;
 }
 .list-item .learn-button{
   border-radius:5px;
   background:#08f;
   padding:5px 10px;
   font-size:12px;
   color:#fff;
   line-height:18px;
 }
 .list-item .right-cell{
   text-align:right;
   display:flex;
   align-items:center;
   justify-content:center;
 }

 .router-link-active{
   color: #fff; 
   font-weight: bold;
   text-decoration:none;
 }
</style>
