import request from '@/utils/request'

const host = process.env.VUE_APP_API_URL
// const host = ''

const API = {
  login: `${host}/api/user/login`,
  logout: `${host}/api/user/logout`,
  getUserInfo: `${host}/api/user/info`,
  createAdmin: '/api/admin/create',
  updateAdmin: `${host}/api/admin/update`,
  refresh: `${host}/api/user/refresh`,
  allAdmin: '/api/admin/list',
  all: '/api/user/list',
}

export function login (parameter) {
  return request({
    url: API.login,
    method: 'post',
    data: parameter
  })
}

export function logout (parameter) {
  return request({
    url: API.logout,
    method: 'post',
    data: parameter
  })
}

export function getUserInfo (parameter) {
  return request({
    url: API.getUserInfo,
    method: 'post',
    data: parameter
  })
}


export function allAdmin (parameter) {
  return request({
    url: API.allAdmin,
    method: 'get',
    params: parameter
  })
}

export function all (parameter) {
  return request({
    url: API.all,
    method: 'get',
    params: parameter
  })
}

export function createAdmin (parameter) {
  return request({
    url: API.createAdmin,
    method: 'post',
    data: parameter
  })
}

export function updateAdmin (parameter) {
  return request({
    url: API.updateAdmin,
    method: 'post',
    data: parameter
  })
}

export function refreshToken (parameter) {
  return request({
    url: API.refresh,
    method: 'post',
    data: parameter
  })
}

